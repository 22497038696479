import React from 'react';
import {
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  SliderMark,
  Box,
  Text,
  Flex,
} from '@chakra-ui/react';

const FixationSlider = props => {
  return (
    <Slider
      defaultValue={props.value}
      min={1}
      max={5}
      step={1}
      aria-label="fixation setting"
      onChangeEnd={val => {
        props.handleChange(val);
      }}
    >
      <SliderMark value={1} mt="3" ml="-1" fontSize="xs">
        <Flex>
          <Text textStyle="bold">Stro</Text>
          <Text mr={1}>ng</Text>
          <Text textStyle="bold"> Fixat</Text>
          <Text>ion</Text>
        </Flex>
      </SliderMark>
      <SliderMark value={4.2} mt="3" fontSize="xs">
        <Flex>
          <Text textStyle="bold">W</Text>
          <Text mr={1}>eak</Text>
          <Text textStyle="bold"> F</Text>
          <Text>ixation</Text>
        </Flex>
      </SliderMark>

      <SliderTrack bg="teal.100">
        <Box position="relative" right={10} />
        <SliderFilledTrack bg="teal" />
      </SliderTrack>
      <SliderThumb boxSize={4} bg="teal" />
    </Slider>
  );
};

export default FixationSlider;
