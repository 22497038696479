import React, { useState } from 'react';
import {
  ChakraProvider,
  Box,
  Text,
  VStack,
  Alert,
  AlertIcon,
  Grid,
  Button,
  Stack,
  Textarea,
  FormControl,
  FormErrorMessage,
  Link,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  useRadioGroup,
  HStack,
} from '@chakra-ui/react';
import axios from 'axios';
import {
  FixationSlider,
  SaccadeSlider,
  MenuBar,
  FontToggle,
} from './Components';

import theme from './theme';
import '@fontsource/lora';
import '@fontsource/inter';
import './App.css';

function App() {
  const [bionicText, setBionicText] = useState(null);
  const [textInput, setTextInput] = useState('');
  const [error, setError] = useState(false);
  const [emptyTextError, setEmptyTextError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [fixation, setFixation] = useState(3);
  const [saccade, setSaccade] = useState(10);
  const [typefaceSerif, setTypefaceSerif] = useState(true);

  const encodedParams = new URLSearchParams();
  encodedParams.append('content', textInput);
  encodedParams.append('response_type', 'html');
  encodedParams.append('request_type', 'html');
  encodedParams.append('fixation', fixation);
  encodedParams.append('saccade', saccade);

  const apiParams = {
    method: 'POST',
    url: 'https://bionic-reading1.p.rapidapi.com/convert',
    headers: {
      'content-type': 'application/x-www-form-urlencoded',
      'X-RapidAPI-Host': 'bionic-reading1.p.rapidapi.com',
      'X-RapidAPI-Key': process.env.REACT_APP_RAPID_API_KEY,
    },
    data: encodedParams,
  };

  const getData = () => {
    //clear out any existing bionictext
    setIsLoading(true);
    setBionicText('');
    setError(false);
    setEmptyTextError(false);

    if (textInput === '') {
      setEmptyTextError(true);
      setIsLoading(false);
    } else {
      //request text conversion from bionic api
      axios
        .request(apiParams)
        .then(function (response) {
          setBionicText(response.data);
          setIsLoading(false);
          console.log(response.data);
        })
        .catch(function (error) {
          console.error(error);
          setError(true);
          setIsLoading(false);
        });
    }
  };

  const handleInputChange = e => {
    let inputValue = e.target.value;
    setTextInput(inputValue);
  };

  const { isOpen, onOpen, onClose } = useDisclosure();

  const adjustFixation = newFixation => {
    setFixation(newFixation);
  };

  const adjustSaccade = newSaccade => {
    setSaccade(newSaccade);
  };

  const toggleOptions = ['Serif', 'Sans Serif'];

  const handleTypefaceChange = value => {
    if (value === 'Serif') {
      setTypefaceSerif(true);
    } else if (value === 'Sans Serif') {
      setTypefaceSerif(false);
    }
  };

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: 'typeface',
    defaultValue: 'Serif',
    onChange: handleTypefaceChange,
  });

  const group = getRootProps();

  return (
    <ChakraProvider theme={theme}>
      <Box textAlign="center" fontSize="xl">
        <Grid p={4}>
          <MenuBar settingsClick={onOpen} />
          <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>⚙️ Settings</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Text textStyle="h3">Typeface</Text>
                <HStack {...group}>
                  {toggleOptions.map(value => {
                    const radio = getRadioProps({ value });
                    return (
                      <FontToggle key={value} {...radio}>
                        {value}
                      </FontToggle>
                    );
                  })}
                </HStack>
                <Text textStyle="h3" mt={10}>
                  Fixation
                </Text>
                <Text fontSize="sm" mt={2} textStyle="body">
                  Controls how many letters are bolded.
                </Text>
                <Box p={2}>
                  <FixationSlider
                    handleChange={adjustFixation}
                    value={fixation}
                  />
                </Box>
                <Text textStyle="h3" mt={10}>
                  Saccade
                </Text>
                <Text fontSize="sm" mt={2} textStyle="body">
                  Controls the number of fixation points.
                </Text>
                <Box p={2}>
                  <SaccadeSlider handleChange={adjustSaccade} value={saccade} />
                </Box>
                <ModalFooter />
              </ModalBody>
            </ModalContent>
          </Modal>

          <VStack spacing={2} justify="flex-start">
            <Text textStyle="h1">Bionic Reader 🦾</Text>
            <Text color="gray.500" textStyle="h2">
              Read faster by converting text to bionic format. Learn more{' '}
              <Link
                color="teal.500"
                href="https://bionic-reading.com/"
                target="_blank"
              >
                here
              </Link>
              .
            </Text>
            <Stack spacing={8} maxW="728px" direction={['column', 'row']}>
              <FormControl isInvalid={emptyTextError}>
                <Textarea
                  placeholder="Paste text or a URL to an article here"
                  value={textInput}
                  onChange={handleInputChange}
                  size="md"
                  minW={['80vw', '25vw']}
                />
                {!emptyTextError ? null : (
                  <FormErrorMessage>Please enter some text</FormErrorMessage>
                )}
              </FormControl>

              <Button
                colorScheme="teal"
                className="make-it-bionic"
                px={12}
                py={6}
                onClick={getData}
                isLoading={isLoading}
                loadingText="Converting text"
              >
                Make it bionic
              </Button>
            </Stack>
            {error ? (
              <Alert maxW="60vw" status="error">
                <AlertIcon />
                There was an error processing your request. Please try again.
              </Alert>
            ) : (
              <Box />
            )}
            <Box
              maxW="728px"
              mx={4}
              textAlign="left"
              textStyle={typefaceSerif ? 'articleSerifText' : 'articleSansText'}
            >
              <div dangerouslySetInnerHTML={{ __html: bionicText }} />
            </Box>
          </VStack>
        </Grid>
      </Box>
    </ChakraProvider>
  );
}

export default App;
