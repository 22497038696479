import React from 'react';
import {
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  SliderMark,
  Box,
  Text,
  Flex,
} from '@chakra-ui/react';

const SaccadeSlider = props => {
  return (
    <Slider
      defaultValue={props.value}
      min={10}
      max={50}
      step={10}
      aria-label="fixation setting"
      onChangeEnd={val => {
        props.handleChange(val);
      }}
    >
      <SliderMark value={10} mt="3" ml="-1" fontSize="xs">
        <Flex>
          <Text textStyle="bold">Man</Text>
          <Text mr={1}>y</Text>
          <Text textStyle="bold">Sacca</Text>
          <Text>des</Text>
        </Flex>
      </SliderMark>
      <SliderMark value={40} mt="3" fontSize="xs">
        <Flex>
          <Text textStyle="bold">Fe</Text>
          <Text mr={1}>wer</Text>
          <Text>Saccades</Text>
        </Flex>
      </SliderMark>

      <SliderTrack bg="teal.100">
        <Box position="relative" right={10} />
        <SliderFilledTrack bg="teal" />
      </SliderTrack>
      <SliderThumb boxSize={4} bg="teal" />
    </Slider>
  );
};

export default SaccadeSlider;
