import React from 'react';
import { IconButton } from '@chakra-ui/react';
import { SettingsIcon } from '@chakra-ui/icons';

const SettingsButton = props => {
  return (
    <IconButton
      size="md"
      fontSize="lg"
      aria-label={`Settings`}
      variant="ghost"
      color="current"
      marginLeft="2"
      icon={<SettingsIcon />}
      {...props}
    />
  );
};

export default SettingsButton;
