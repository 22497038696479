import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
  fonts: {
    heading: 'Open Sans, sans-serif',
    body: 'Inter, sans-serif',
  },
  textStyles: {
    h1: {
      // you can also use responsive styles
      fontSize: ['2.5rem'],
      fontWeight: 'bold',
      lineHeight: '110%',
      letterSpacing: '-2%',
      fontFamily: 'Open Sans, sans-serif',
    },
    h2: {
      fontFamily: 'Inter, sans-serif',
    },
    h3: {
      fontFamily: 'Inter, sans-serif',
      fontWeight: 'bold',
    },
    body: {
      fontFamily: 'Inter, sans-serif',
    },

    articleSerifText: {
      paddingBottom: '1rem',
      fontFamily: 'Lora, serif',
    },

    articleSansText: {
      paddingBottom: '1rem',
      fontFamily: 'Inter, sans-serif',
    },

    bold: {
      fontWeight: 'bold',
    },
  },
});

export default theme;
