import { ColorModeScript } from '@chakra-ui/react';
import React, { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';
import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module';

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);

ReactGA.initialize([
  {
    trackingId: 'G-XMSKWKJW6V',
    // gaOptions: {...}, // optional
    // gtagOptions: {...}, // optional
  },
]);

const tagManagerArgs = {
  gtmId: 'GTM-N7B62DR',
};

TagManager.initialize(tagManagerArgs);

root.render(
  <StrictMode>
    <ColorModeScript />
    <App />
  </StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
