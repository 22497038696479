import React from 'react';
import {
  Menu,
  MenuItem,
  IconButton,
  Icon,
  MenuList,
  MenuButton,
  Flex,
  useMediaQuery,
  Button,
  HStack,
  Box,
} from '@chakra-ui/react';
import { HamburgerIcon, ChatIcon } from '@chakra-ui/icons';
import { FaHeart, FaTwitter } from 'react-icons/fa';
import { SettingsButton, ColorModeSwitcher } from './';

const MenuBar = props => {
  const [isMobile] = useMediaQuery('(max-width: 768px)');

  const handleButtonClick = () => {
    window.open('https://www.buymeacoffee.com/zachcapshaw', '_blank');
  };

  const handleTwitterClick = () => {
    window.open('https://twitter.com/zachcapshaw', '_blank');
  };

  const handleFeedbackClick = () => {
    window.open('https://forms.gle/UPFNYqAsgM74nTUx9', '_blank');
  };

  return (
    <Flex justify="space-between" mb={4}>
      {isMobile ? (
        <Menu>
          <MenuButton
            as={IconButton}
            aria-label="Options"
            icon={<HamburgerIcon />}
            variant="outline"
            className="hamburger-menu"
          />
          <MenuList>
            <MenuItem
              icon={<Icon color="tomato" as={FaHeart} />}
              onClick={handleButtonClick}
              className="buy-me-a-coffee"
            >
              Buy me a coffee
            </MenuItem>
            <MenuItem
              icon={<ChatIcon />}
              onClick={handleFeedbackClick}
              className="feedback"
            >
              Give us feedback
            </MenuItem>
            <MenuItem
              icon={<Icon as={FaTwitter} />}
              onClick={handleTwitterClick}
              className="twitter"
            >
              Follow on Twitter
            </MenuItem>
          </MenuList>
        </Menu>
      ) : (
        <Button
          justifySelf="flex-start"
          variant="link"
          leftIcon={<ChatIcon />}
          onClick={handleFeedbackClick}
          className="feedback"
        >
          Give us feedback
        </Button>
      )}

      <HStack justify="flex-end">
        {isMobile ? (
          <div />
        ) : (
          <Box>
            {' '}
            <Button
              leftIcon={<Icon color="tomato" as={FaHeart} />}
              variant="outline"
              fontSize="sm"
              textStyle="buttonText"
              onClick={handleButtonClick}
              className="buy-me-a-coffee"
            >
              Buy me a coffee
            </Button>
            <IconButton
              size="md"
              fontSize="lg"
              aria-label={`Settings`}
              variant="ghost"
              color="current"
              marginLeft="2"
              icon={<Icon as={FaTwitter} />}
              onClick={handleTwitterClick}
              className="twitter"
            />
          </Box>
        )}

        <SettingsButton
          justifySelf="flex-end"
          onClick={props.settingsClick}
          className="settings"
        />
        <ColorModeSwitcher justifySelf="flex-end" className="dark-mode" />
      </HStack>
    </Flex>
  );
};

export default MenuBar;
